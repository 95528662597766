import {
  FaLinkedinIn,
  FaTwitter,
  FaTelegramPlane,
  FaDiscord
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "https://opensea.io/PARADIGMA_NFT_METAVERSE",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/NFTParadigma",
  },
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/PARAD_777",
  },
  {
    thumb: mediumIcon,
    url: "https://medium.com/@paradigmanft",
  },
  {
   icon:  <FaDiscord />,
    url: "https://discord.gg/KsPNgRvAmX",
  },
];

export default data;
