import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'PARADIGMA NFT METAVERSE is an NFT marketplace + Sci-fi + an innovative P2E gaming platform that combines several directions, including the stacking of all NFT characters through various missions. Paradigma is a totalizer with numerous quizzes, riddles, tasks, and solutions.',
  aboutDescription2: 'Membership in secret societies! Only in the Paradigma NFT metaverse can you become a member of any secret society, join various secret orders, closed gatherings, and conspiracies. Participants can also participate in votes and elections of any format, place bets on election outcomes and win based on the results, create their own totalizers, and attract participants to take part in drawings. invest@paradnft.io',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Gamming NFTs",
            text: "In the Paradigma NFT project there are several basic strategies, which in game format are the core of the Metaverse. You become a member of the metaverse and express your views, interests and opinions, thus choosing your direction, interests and like-minded people in the future game."
        },
        {
            icon: aboutCardIcon2,
            title: "Play to Earn",
            text: 'Every NFT received or purchased gives automatic profit to its owner forever! Any character or location, with the help of STAKING functionality is a daily income.'
        },
        {
            icon: aboutCardIcon3,
            title: "Metaverse Ready",
            text: 'The Paradigma NFT Metavers project according to its roadmap has an order of priority in its launch.  To launch a Metaversion, the first task is to identify the number of participants in their chosen paradigm. It is the majority of participants of the winning paradigm that will determine which paradigm will be launched first. As a result of all the votes, all nine paradigms will be launched in their turn.'
        }
    ]
}

export default data;