import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 01 ",
        subtitle: "Project launch",
        text: "Conceptualization of the Paradigma NFT (2021-2022), Token creation (Jul-27-2022), Launch of social networks (2022-2023), Launch of official website (2023)"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 02",
        subtitle: "Marketing 2023-2024",
        text: "Marketing campaigns, Airdrop activity, Announcements of listings on Centralized exchanges."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 03",
        subtitle: "Metaverse Launch",
        text: "Metaverse Launch 2024, Community NFT Airdrop 1 million tokens every nine days 2024"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04",
        subtitle: "That's not all",
        text: "Coming Soon"
    }, 
]

export default data;