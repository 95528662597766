const data = [
  {
    title: "What is Paradigma?",
    text: "Paradigma is a multifunctional gaming platform with unique functionality of NFT totalizator, NFT marketplace, as well as a Metaverse of all worldviews and possibilities.",
  },
  {
    title: "What network does your project use?",
    text: "The PARAD token is created on ERC-777 on the BSC network.",
  },
  {
    title: "What is the smart contract address of the token?",
    text: "0xBDa093C16347b5B106bC5BF9aFd0DdEef85eA60C",
  },
  {
    title: "Where can I sell PARAD tokens?",
    text: "The token sale will be possible on DEX already this year.",
  },
  {
    title: "How can I get NFT tokens for free?",
    text: "PARADIGMA NFT METAVERS tokens can be obtained now by taking a small survey at the link.",
  },
  {
    title: "What makes your Meta version different from others?",
    text: "Our metaverse has unlimited potential of functionality and features. The platform grows and develops due to new needs of its users. There is a problem - there is a solution! On our platform you can become the owner of a whole world in NFT format. With an unlimited number of characters and locations. In our meta universe in the virtual world it is possible to become the owner of everything that is in the real world. Unlimited possibilities and unlimited potential distinguishes our project from others!",
  },
];

export default data;
